import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/display/page.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {
      /* ![1](../../content/assets/about.jpg) */
    }
    <p>{`Hello! My name is Richard.`}</p>
    {
      /*
      ## My life
      I enjoy running, mountain biking.
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      